import styled from "styled-components";

export const GradientSection = styled.section`
  margin-top: 100px;
  background-image: url(./static/images/homepage/gradient.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 1095px) {
    background-image: url(./static/images/homepage/gradient-ipad.png);
  }

  @media (max-width: 480px) {
    background-image: url(./static/images/homepage/gradient-phone.png);
    margin-top: 0;
  }
`;

export const GradContainer = styled.div`
  display: flex;
	flex-direction: column;
  width: 920px;
	height: 416px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    height: 416px;
    width: 100%;
    margin: 0;
    padding: 0px 20px;
  }

  @media (max-width: 680px) {
		height: 309px;
  }
`;

export const GradText = styled.p`
  font-family: "ivypresto-display",serif;
	font-style: normal;
	font-weight: 700;
	font-size: 64px;
	line-height: 110%;

	text-align: center;

	color: #FFFFFF;
	margin: 0 auto;
	padding: 104px 0px 48px;
	width: 927;

  @media (max-width: 1024px) {
		font-size: 64px;
    padding: 72px 0px 48px;
  }

  @media (max-width: 680px) {
    width: 288px;
		font-size: 26px;
		padding: 56px 0px 32px;
  }
`;

export const ContentCont = styled.section`
  display: flex;
	flex-direction: row;
	justify-content: space-between;

	background: #FFFFFF;

	box-shadow: 0px 134.283px 53.7132px rgba(0, 0, 0, 0.01), 0px 75.1985px 45.6563px rgba(0, 0, 0, 0.05), 0px 33.5708px 33.5708px rgba(0, 0, 0, 0.09), 0px 8.05699px 18.7996px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 5.03757px 15.1127px rgba(0, 0, 0, 0.06);
	border-radius: 20px;
	width: 1120px;
	margin: auto ;
	padding: 48px 40px;
	margin-top: -120px;

  @media (max-width: 1200px) {
		width: 1000px;
		padding: 40px auto;
	  justify-content: space-between;
  }

  @media (max-width: 1024px) {
		width: 680px;
		margin-top: -152px;
  }

  @media (max-width: 680px) {
		flex-wrap: wrap;
    gap: 32px 40px;

		width: 300px;
    height: 267px;

		padding: 32px 40px;
		margin-top: -135px;
  }
`;

export const Col = styled.div`
	flex: 1;
	display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 680px) {
    width: 40%;
		flex: auto;
    gap: 8px;

    svg {
      width: 56px;
      height: 56px;
    }
  }
`;

export const Title = styled.h4`
  font-family: 'Satoshi', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 44px;
	line-height: 120%;

	text-align: center;

	color: #252527;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 140%;
  }

  @media (max-width: 680px) {
    font-size: 18px;
  }
`;
