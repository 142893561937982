import styled from 'styled-components';

export const ButtonWhite = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 30px;
  gap: 8px;

  background: #ffffff;
  border-radius: 40px;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;

  color: #252527;

  @media (max-width: 1024px) {
    padding: 12px 22px;
    span {
      font-size: 16px;
    }
  }

  @media (max-width: 680px) {
    font-size: 15px;
    padding: 7px 16px;
  }
`;

export const ScheduleButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 30px;
  gap: 8px;

  border: 1px solid;
  border-color: ${({ black }) => (black ? '#252527' : '#FFFFFF')};
  border-radius: 40px;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;

  color: ${({ black }) => (black ? '#252527' : '#FFFFFF')};
  background-color: transparent;

  &:hover {
    background-color: ${({ black }) => (black ? '#252527' : '#FFFFFF')};
    color: ${({ black }) => (black ? '#FFFFFF' : '#252527')};
  }

  @media (max-width: 680px) {
    padding: 7px 12px;
    font-size: 14px;
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 48px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background: #f8f8fa;

  @media (max-width: 680px) {
    height: 56px;
    flex-direction: column;
    gap: 0px;
  }
`;

export const SpringText = styled.p`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;

  color: #8c38da;

  @media (max-width: 680px) {
    font-size: 15px;
  }
`;

export const FreeText = styled.p`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;

  color: #2c2c3a;

  @media (max-width: 680px) {
    font-size: 12px;
  }
`;

export const DeadlineText = styled.p`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;

  color: #a7a7bf;

  @media (max-width: 680px) {
    font-size: 12px;
  }
`;
