import { RoleFeat, RoleFeatsLayout } from '../roles/index.styles';
import {
  ChurchOSLayout,
  DescriptLayout,
  HeadingOne,
  HeadingTwo,
  ImgCont,
  ProductDescription,
  ProductItem,
  ProductsLayout,
  ProductTitle,
} from './index.styles';

import TickIcon from '../../../assets/images/homepage/orange-tick.svg';

const Products = [
  {
    title: '<span>Seamless</span> Communication and Engagement',
    img: './static/images/homepage/seemless-communication.png',
    description:
      'At the heart of the Togather platform is an app purpose-built for community and seamless connection. No more needing to use several messaging apps to get your updates, media, encouragements, devotionals, online service, and all the other resources it takes to build a thriving community. It all happens in one place people love to use. Not on iOS? No problem! All of the group, messaging, leader tools, and homefeed resources can be accessed from a mobile or desktop browser too.',
    benefits: [
      'Custom Homefeed content with analytics',
      'One Inbox for church messages and group chats',
      'Prayer Requests and Praise Reports in group chats',
    ],
  },
  {
    title: 'Your Groups and Events <span>In One Place</span>',
    img: './static/images/homepage/your-groups.png',
    description:
      'Visitors and Members of your church can easily find groups they’re interested in. Search by areas of your city or view by group type–there’s a way for everyone to get involved. Our security measures ensures everyone is safely admitted to groups. Members can easily see the latest events for church and small groups, RSVP with a tap, and smart notifications send helpful reminders when new events are added.',
    benefits: [
      'Group search and chats in one place',
      'Create events, track RSVPs, and check people in',
      'Smart notifications for RSVPs and events',
    ],
  },
  {
    title: 'Leader Tools with <span>Smart Notifications</span>',
    img: './static/images/homepage/leader-tools.png',
    description:
      'Your volunteer leaders will be equipped with tools that help them connect with your team and shepherd their groups seamlessly. Submitting attendance is no longer a headache and can be done in just a few taps in the custom Leader Tools section. Know the health of your leaders and members with powerful reporting tools and keep track of their journey, you even know which resources your church responds to most.',
    benefits: [
      'Leaders get helpful reporting reminders',
      'View and export attendance analytics',
      'Leaders can easily add and edit group events',
    ],
  },
  {
    title: '<span>Real-Time Data</span> in a Flexible Admin Suite',
    img: './static/images/homepage/real-data.png',
    description:
      'A thriving community needs a powerful backend tool, and your team will have just that with your Admin Suite. View real-time data, curate your app’s custom homefeed, view member journey health, communicate with members on the app, and much more. Account setup happens in minutes, and we’ll provide resources to help onboard your church as easily as possible.',
    benefits: [
      'Real-time data dashboard and reports',
      'Member notifications and community security',
      'Easy setup and customization tools',
    ],
  },
];

const ChurchOS = () => {
  return (
    <ChurchOSLayout>
      <HeadingOne>
        We built Togather’s ChurchOS to grow your community, lower your costs,
        and <span>set a new standard</span> in church tech.
      </HeadingOne>
      <HeadingTwo>How the Togather ChurchOS can help you</HeadingTwo>
      <ProductsLayout>
        {Products.map((prodItem, index) => (
          <ProductItem key={'prod' + index}>
            <ImgCont>
              <img src={prodItem.img} />
            </ImgCont>
            <DescriptLayout>
              <ProductTitle
                dangerouslySetInnerHTML={{ __html: prodItem.title }}
              />
              <ProductDescription>{prodItem.description}</ProductDescription>
              <RoleFeatsLayout>
                {prodItem.benefits.map((bFeat, i) => (
                  <RoleFeat key={'bFeat' + index + i}>
                    <TickIcon />
                    <p>{bFeat}</p>
                  </RoleFeat>
                ))}
              </RoleFeatsLayout>
            </DescriptLayout>
          </ProductItem>
        ))}
      </ProductsLayout>
    </ChurchOSLayout>
  );
};

export default ChurchOS;
