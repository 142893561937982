import { useRouter } from 'next/router';
import {
  Button,
  Container,
  MainHeading,
  Paragraph,
  ParrallexContainer,
} from './index.styles';

const ChurchBuilders = () => {
  const router = useRouter();
  return (
    <ParrallexContainer id="church-builders">
      <Container>
        <MainHeading>Created by Community Church Builders</MainHeading>
        <Paragraph>
          Togather was born out of a heart for the church and a desire for
          stronger communities. At a time when our world feels more divided than
          ever and our focus is pulled in countless directions, we should be
          looking to our church communities for the connective tissue that keeps
          us together. But having gone through the last few years and now
          rebuilding after the impacts of the pandemic, we looked at landscape
          of church technology and were left wanting. It was time to set a new
          standard for church tech and build a modern, forward-thinking platform
          that creates a two-way relationship with church and its members,
          assists volunteer leaders in delightful ways, and enables deeper
          discipleship for staff and pastors. This is just the beginning.
        </Paragraph>
        <Button onClick={() => router.push('/our-story')}>
          <span>Learn more about our story</span>
        </Button>
      </Container>
    </ParrallexContainer>
  );
};

export default ChurchBuilders;
