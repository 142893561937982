import styled from "styled-components";

export const ParrallexContainer = styled.section`
  background-image: url(./static/images/homepage/builders-xl.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 709px;

  margin-top: 200px;
  display: flex;
	justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    background-image: url(./static/images/homepage/builders-lg.png);
  }

  @media (max-width: 1024px) {
    margin-top: 120px;
    background-image: url(./static/images/homepage/builders-md.png);
  }

  @media (max-width: 680px) {
    margin-top: 80px;
    height: 520px;
    background-image: url(./static/images/homepage/builders-sm.png);
  }
`;

export const Container = styled.div`
	margin: 0 auto;
	width: 873px;
  gap: 32px;

	display: flex;
	flex-direction: column;
	justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 560px;
  }

  @media (max-width: 680px) {
    width: 100%;
    padding: 0px 16px;
	  margin: 0;
  }
`;

export const MainHeading = styled.h3`
	font-family: "ivypresto-display",serif;
	font-style: normal;
	font-weight: 700;
	font-size: 64px;
	line-height: 110%;
  padding: 0px 20px;

	text-align: center;

	color: #FFFFFF;

  @media (max-width: 1024px) {
    padding: 0px 20px;
    font-size: 48px;
  }

  @media (max-width: 680px) {
    font-size: 26px;
  }
`;

export const Paragraph = styled.p`
  font-family: 'Satoshi', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 140%;
  padding: 0px 86px;

	text-align: center;

	color: #FFFFFF;

  @media (max-width: 1024px) {
    padding: 0px;
    font-size: 18px;
  }

  @media (max-width: 680px) {
    font-size: 15px;
    line-height: 120%;
  }
`;

export const Button = styled.button`
  display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 9px 20px;
	gap: 8px;

	background: rgba(255, 255, 255, 0.1);
	border-radius: 40px;
	border: none;

  span {
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;

    color: #FFFFFF;
  }

  @media (max-width: 680px) {
    span {
      font-size: 15px;
    }
  }
`;
