import { useState } from 'react';
import {
  FeatDescript,
  FeatureName,
  FeaturesLayout,
  FeaturesLayoutOutter,
  HeadingOne,
  HeadingTwo,
  Paragraph,
  StartedButton,
  TextLayout,
  ProgressBar,
  PhoneLayout,
  NavigatorLayout,
  FeatureTextCont,
} from './index.styles';
import { useRouter } from 'next/router';
import { LinearProgress } from '@material-ui/core';
import RightIcon from '../../../assets/images/homepage/feat-right-arrow.svg';
import LeftIcon from '../../../assets/images/homepage/feat-left-arrow.svg';

const featImages = {
  homefeed: './static/images/homepage/iphone/homefeed.png',
  inbox: './static/images/homepage/iphone/inbox.png',
  groupEvents: './static/images/homepage/iphone/group-events.png',
  LeaderTools: './static/images/homepage/iphone/leader-tools.png',
  groups: './static/images/homepage/iphone/groups.png',
  payerRequest: './static/images/homepage/iphone/prayer-request.png',
  broadcast: './static/images/homepage/iphone/broadcast-message.png',
  watch: './static/images/homepage/iphone/watch-togather.png',
  attendance: './static/images/homepage/iphone/attendance.png',
  community: './static/images/homepage/iphone/community-security.png',
};

const featureItems = [
  {
    id: 1,
    title: 'Custom homefeed',
    description:
      'Use a library of engaging resources your church will love. Analytics show what they engage with.',
    img: 'homefeed',
  },
  {
    id: 2,
    title: 'Unified inbox',
    description:
      'No more checking multiple apps. Have all your church conversations in one place.',
    img: 'inbox',
  },
  {
    id: 3,
    title: 'Group & EVENT RSVP',
    description:
      'Leaders and Staff create small group and church events easily, then members RSVP with just a tap.',
    img: 'groupEvents',
  },
  {
    id: 4,
    title: 'Leader Tools',
    description:
      'Empower leaders to grow their groups, create a welcoming experience, and connect well.',
    img: 'LeaderTools',
  },
  {
    id: 5,
    title: 'GroupS',
    description:
      'Easily create different group types custom to your church. Members can find and join effortlessly.',
    img: 'groups',
  },
  {
    id: 6,
    title: 'prayer requests',
    description:
      'Share prayer requests and praise reports directly in group chats. View in your prayer journal too.',
    img: 'payerRequest',
  },
  {
    id: 7,
    title: 'Broadcast Messages',
    description:
      'Replace mass-texting with an elegant, multi-media message to everyone in your church.',
    img: 'broadcast',
  },
  {
    id: 8,
    title: 'Stream & WatchTogether',
    description:
      'Schedule recorded and live videos to play in your app and see metrics. Groups can watch together too.',
    img: 'watch',
  },
  {
    id: 9,
    title: 'Community security',
    description:
      'Security is paramount to community, so it’s easy to flag messages and oversee the health of your groups.',
    img: 'community',
  },
  {
    id: 10,
    title: 'Take attendance',
    description:
      'Submitting attendance as a Leader is just a few taps away now. Add guests or notes; it’s now quick to do.',
    img: 'attendance',
  },
];

const mobileWidth = 290;
const imageGap = 78;
const mobileWidthMedia680 = 145;
const imageGapMedia680 = 150;

const contentWidth = mobileWidth + imageGap;
const contentWidthMedia680 = mobileWidthMedia680 + imageGapMedia680;

const Features = ({ width }) => {
  const router = useRouter();
  const [activeFeat, setActiveFeat] = useState(0);

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <FeaturesLayoutOutter>
        <HeadingOne>
          A <span>Proven Method</span> to Grow Your Church: Engaged Small Groups
        </HeadingOne>
        <Paragraph>
          Having your team use a dozen apps to talk to your members and manage
          small groups is a waste of time. Get the platform that has shown to
          increase attendance and new member retention by up to 60%.
        </Paragraph>
        <FeaturesLayout>
          <TextLayout>
            <HeadingTwo>
              Your church.
              <br />
              All in one place.
            </HeadingTwo>
            <StartedButton
              onClick={() => {
                if (typeof window.gtag !== 'undefined') {
                  window.gtag('event', 'view_pricing');
                }
                router.push(
                  '/pricing' + (router.query.r ? `?r=${router.query.r}` : '')
                );
              }}
            >
              Get Started
            </StartedButton>
            {featureItems.map((fItem, index) => (
              <FeatureTextCont
                key={"featureText"+index}
                style={{opacity: activeFeat === index ? 1 : 0}}
              >
                <FeatureName>{fItem.title}</FeatureName>
                <FeatDescript>{fItem.description}</FeatDescript>
              </FeatureTextCont>
            ))}
            <ProgressBar>
              <LinearProgress
                variant="determinate"
                value={(activeFeat + 1) * 10}
              />
            </ProgressBar>
          </TextLayout>
          <PhoneLayout
            imageCount={featureItems.length}
            mobileWidth={mobileWidth}
            imageGap={imageGap}
            mobileWidthMedia680={mobileWidthMedia680}
            imageGapMedia680={imageGapMedia680}
          >
            <div className="item-contianer">
              {featureItems.map((item, index) => (
                <img
                  key={'img' + item.img}
                  className="cross-dissolve"
                  src={featImages[item.img]}
                  alt={item.img}
                  style={{
                    transform:
                      index < activeFeat
                        ? 'matrix(0.9, 0, 0, 0.9,' +
                          (index !== 0 ? '-' : '') +
                          index * (width < 680 ? contentWidthMedia680 : contentWidth) +
                          ', 0)'
                        : 'matrix(1, 0, 0, 1, -' +
                          activeFeat * (width < 680 ? contentWidthMedia680 : contentWidth) +
                          ', 0)',
                    opacity: index < activeFeat ? 0 : 1,
                  }}
                />
              ))}
            </div>
            <div class="item-mask">&nbsp;</div>
          </PhoneLayout>
        </FeaturesLayout>
        <NavigatorLayout>
          <div className="inner">
            <button
              disabled={activeFeat === 0}
              onClick={() => setActiveFeat(activeFeat - 1)}
            >
              <RightIcon />
            </button>
            <button
              disabled={activeFeat === featureItems.length - 1}
              onClick={() => setActiveFeat(activeFeat + 1)}
            >
              <LeftIcon />
            </button>
          </div>
        </NavigatorLayout>
      </FeaturesLayoutOutter>
    </div>
  );
};

export default Features;
