import styled from 'styled-components';

export const FeaturesLayoutOutter = styled.div`
  width: 1120px;
  margin: 200px auto 100px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    width: 100%;
    margin: 200px 0 60px;
    padding: 0 32px;
  }

  @media (max-width: 680px) {
    margin-top: 80px;
    margin-bottom: 40px;
    padding: 0 16px;
  }
`;

export const HeadingOne = styled.h2`
  font-family: 'ivypresto-display', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 110%;

  text-align: center;

  color: #252527;
  width: 1000px;

  span {
    font-family: 'ivypresto-display', serif;
    color: #feb240;
  }

  @media (max-width: 1024px) {
    width: 100%;
    font-size: 64px;
  }

  @media (max-width: 680px) {
    width: 288px;
    font-size: 48px;
  }
`;

export const Paragraph = styled.p`
  width: 571px;
  margin: 48px auto 0;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 140%;

  text-align: center;

  color: #2c2c3a;

  @media (max-width: 1024px) {
    width: 457px;
    font-size: 18px;
    margin-top: 32px;
  }

  @media (max-width: 680px) {
    width: 288px;
    font-size: 15px;
    line-height: 120%;
    margin-top: 24px;
  }
`;

export const FeaturesLayout = styled.div`
  margin: 104px auto 40px;
  width: 774px;
  height: 600px;

  display: flex;
  flex-direction: row;

  @media (max-width: 774px) {
    width: 100%;
    margin: 88px 0 40px;
  }

  @media (max-width: 680px) {
    width: 200px;
    margin: 48px auto 24px;
    position: relative;
    height: 527px;
  }
`;

export const TextLayout = styled.div`
  width: 444px;
  height: 600px;
  margin-right: 40px;
  position: relative;

  @media (max-width: 774px) {
    width: 53%;
  }

  @media (max-width: 680px) {
    width: 100%;
    margin-right: 0;
    height: 527px;

    position: absolute;
    top: 0;
  }
`;

export const HeadingTwo = styled.h3`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;

  color: #252527;
  width: 324px;

  @media (max-width: 680px) {
    width: 100%;
    font-size: 22px;
    text-align: center;
  }
`;

export const StartedButton = styled.button`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 20px;
  gap: 8px;

  border: 1px solid #252527;
  border-radius: 40px;
  background-color: #fff;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #252527;

  @media (max-width: 680px) {
    font-size: 15px;
    margin: 8px auto 0;
    padding: 7px 16px;
    border-radius: 20px;
  }
`;

export const FeatureTextCont = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 300px;
  position: absolute;
  bottom: 20px;
  right: 0;

  transition: opacity 500ms ease-in-out;

  @media (max-width: 680px) {
    width: 210px;
    bottom: 16px;
    right: initial;
  }
`;

export const FeatureName = styled.h4`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 120%;

  text-transform: uppercase;

  text-align: right;
  color: #252527;

  @media (max-width: 680px) {
    font-size: 14px;
    text-align: center;
  }
`;

export const FeatDescript = styled.p`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;

  text-align: right;
  color: #2c2c3a;  

  @media (max-width: 680px) {
    font-size: 12px;
    line-height: 120%;
    text-align: center;
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  width: 100px;

  .MuiLinearProgress-root {
    border-radius: 2px;
  }

  .MuiLinearProgress-colorPrimary {
    background: rgba(37, 37, 39, 0.1);
  }

  .MuiLinearProgress-barColorPrimary {
    background: #a7a7bf;
    border-radius: 2px;
  }

  @media (max-width: 680px) {
    right: 50px;
  }
`;

export const PhoneLayout = styled.div`
  --lockscreen-image-height: 600px;
  --lockscreen-image-width: ${({ mobileWidth }) => mobileWidth + 'px'};
  --gallery-item-gap: ${({ imageGap }) => imageGap + 'px'};
  --image-count: ${({ imageCount }) => (imageCount ? imageCount : 0)};
  --gallery-item-width: calc(
    var(--lockscreen-image-width) + var(--gallery-item-gap)
  );

  width: var(--lockscreen-image-width);
  height: var(--lockscreen-image-height);
  overflow: visible;
  position: relative;

  .item-contianer {
    height: var(--lockscreen-image-height);
    display: flex;
    flex-direction: row;
    gap: var(--gallery-item-gap);

    img {
      transition: transform 500ms ease-in, opacity 400ms ease-in-out;
    }

    .cross-dissolve {
      will-change: transform;
      width: 100%;
    }
  }

  .item-mask {
    /* background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100.29%); */
    background: linear-gradient(
      90deg,
      transparent calc(var(--gallery-item-width) * 1),
      rgba(255, 255, 255, 0.15) calc(var(--gallery-item-width) * 1.1),
      rgba(255, 255, 255, 0.7) calc(var(--gallery-item-width) * 1.4),
      rgba(255, 255, 255, 0.82) calc(var(--gallery-item-width) * 1.47),
      rgba(255, 255, 255, 0.92) calc(var(--gallery-item-width) * 1.56),
      rgba(255, 255, 255, 0.954) calc(var(--gallery-item-width) * 1.6),
      #FFFFFF calc(var(--gallery-item-width) * 1.7)
    );
    width: calc(var(--gallery-item-width) * var(--image-count));
    height: var(--lockscreen-image-height);
    position: absolute;
    top: 0;
  }

  @media (max-width: 680px) {
    --lockscreen-image-height: 300px;
    --lockscreen-image-width: ${({ mobileWidthMedia680 }) => mobileWidthMedia680 + 'px'};
    --gallery-item-gap: ${({ imageGapMedia680 }) => imageGapMedia680 + 'px'};


    position: absolute;
    top: 132px;
    left: 27.5px;
  }
`;

export const NavigatorLayout = styled.div`
  margin: 0px auto;
  width: 774px;
  height: 56px;
  position: relative;

  .inner {
    left: 565px;
    position: absolute;

    display: flex;
    flex-direction: row;
    gap: 32px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 56px;
      height: 56px;

      background: rgba(167, 167, 191, 0.1);
      border-radius: 50%;

      &:disabled {
        opacity: 0.6;
      }
    }
  }

  @media (max-width: 774px) {
    .inner {
      left: calc(100% - 276px);
    }
  }

  @media (max-width: 680px) {
    width: 80px;
    height: 32px;

    .inner {
      left: 0;
      gap: 16px;

      button {
        height: 32px;
        width: 32px;

        svg {
          width: 6px;
          height: 12px;
        }
      }
    }
  }
`;
