import styled from "styled-components";

export const ChurchOSLayout = styled.div`
  margin: 200px auto 0;
  width: 1120px;

  @media (max-width: 1200px) {
    width: 100%;
    margin: 120px 0;
    padding: 0px 32px;
  }

  @media (max-width: 680px) {
    margin: 80px 0;
    padding: 0px 29px;
  }
`;

export const HeadingOne =  styled.h1`
  font-family: "ivypresto-display",serif;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 110%;
  /* or 88px */

  text-align: center;
  color: #252527;

  span {
    font-family: "ivypresto-display",serif;
    color: #FEB240;
  }

  @media (max-width: 1024px) {
    font-size: 56px;
  }

  @media (max-width: 680px) {
    font-size: 32px;
    width: 262px;
    margin: 0 auto;
  }
`;

export const HeadingTwo =  styled.h2`
  width: 927px;
  margin: 0 auto;
  margin-top: 200px;
  font-family: "ivypresto-display",serif;
  font-weight: 700;
  font-size: 80px;
  line-height: 110%;
  /* or 88px */

  text-align: center;
  color: #252527;

  @media (max-width: 1024px) {
    width: 680px;
    font-size: 56px;
    margin-top: 120px;
  }

  @media (max-width: 680px) {
    font-size: 32px;
    width: 270px;
    margin: 80px auto 0;
  }
`;

export const ProductsLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 128px;

  margin-top: 128px;

  @media (max-width: 1024px) {
    margin-top: 64px;
    gap: 64px;
  }

  @media (max-width: 680px) {
    margin-top: 32px;
    gap: 32px;
  }
`;

export const ProductItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const ImgCont = styled.div`
  flex: 1;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }
`;

export const DescriptLayout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 0px 70px;
  }

  @media (max-width: 680px) {
    padding: 0;
  }
`;

export const ProductTitle = styled.p`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;

  color: #252527;

  span {
    font-family: 'Satoshi', sans-serif;
    color: #FEB240;
  }

  @media (max-width: 680px) {
    font-size: 18px;
  }
`;

export const ProductDescription = styled.p`
  margin-top: 32px;
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #252527;

  @media (max-width: 1024px) {
    margin-top: 24px;
  }

  @media (max-width: 680px) {
    margin-top: 8px;
    font-size: 12px;
    line-height: 120%;
  }
`;

