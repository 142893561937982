import { useRouter } from 'next/router';
import HomeHeader from '../header';
import { ButtonWhite, ScheduleButton } from '../index.styles';
import {
  HeroTextLayout,
  Hero,
  HeroText,
  HeroBtnContainer,
  HeroButton,
  Upperlayer,
} from './index.styles';

const HeroComp = ({
  fixHeader,
  showModal,
  width,
  lightMode,
  commonQuestions,
  ourStoryPage = null,
  offToLandingpage = false,
  withBanner,
}) => {
  const router = useRouter();

  return (
    <Hero id="hero-section" ourStoryPage={ourStoryPage}>
      <HomeHeader
        fixHeader={fixHeader}
        showModal={showModal}
        width={width}
        lightMode={lightMode}
        offToLandingpage={offToLandingpage}
        withBanner={withBanner}
      />
      <HeroTextLayout>
        <HeroText>
          <h1>
            {ourStoryPage ? (
              <>Church tech should be better</>
            ) : (
              <>Leader Tools that Grow Your Church</>
            )}
          </h1>
          <h2>
            {ourStoryPage ? (
              <>This belief led us here.</>
            ) : (
              <>
                Simplify your administrative work and engage your members with
                the tool made by church leaders, for church leaders. Start your
                new ChurchOS for free today.
              </>
            )}
          </h2>
          {!ourStoryPage && (
            <HeroBtnContainer>
              <ButtonWhite
                onClick={() => {
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'view_pricing');
                  }
                  router.push(
                    '/pricing' + (router.query.r ? `?r=${router.query.r}` : '')
                  );
                }}
                showBorder
                style={{ marginTop: '0px' }}
              >
                Get Started Risk Free
              </ButtonWhite>
              <ScheduleButton onClick={() => router.push('/demo')}>
                Schedule a Demo
              </ScheduleButton>
            </HeroBtnContainer>
          )}
        </HeroText>
      </HeroTextLayout>
    </Hero>
  );
};

export default HeroComp;
