import { useState } from 'react';
import {
  Card,
  CardBody,
  CardDescription,
  CardImage,
  CardImageContianers,
  CardTitle,
  Frame,
  HeadingOne,
  Paragraph,
  QuoteSection,
  RoleFeat,
  RoleFeatsLayout,
  RolesLayout,
  Tab,
  TabArrow,
  TabsContainer,
} from './index.styles';
import GroupIcon from '../../../assets/images/homepage/group.svg';
import InboxIcon from '../../../assets/images/homepage/inbox.svg';
import LeftArrowIcon from '../../../assets/images/homepage/left-arrow.svg';
import RightArrowIcon from '../../../assets/images/homepage/right-arrow.svg';
import HomefeedIcon from '../../../assets/images/homepage/homefeed-noti.svg';
import EventIcon from '../../../assets/images/homepage/events.svg';
import StreamingIcon from '../../../assets/images/homepage/streaming.svg';
import StatsIcon from '../../../assets/images/homepage/stats.svg';
import UserIcon from '../../../assets/images/homepage/user.svg';
import WelcomeVideoIcon from '../../../assets/images/homepage/welcome-vid.svg';
import { GradientSection } from '../costing/index.styles';

const tabKeys = {
  PASTOR: 1,
  STAFF: 2,
  LEADER: 3,
  MEMBER: 4,
};

const roleImages = {
  [tabKeys.PASTOR]: './static/images/homepage/pastor-feat.png',
  [tabKeys.STAFF]: './static/images/homepage/staff-feat.png',
  [tabKeys.LEADER]: './static/images/homepage/leaders-feat.png',
  [tabKeys.MEMBER]: './static/images/homepage/members-feat.png',
};

const RoleFeatures = [
  {
    key: tabKeys.PASTOR,
    title: 'Pastor',
    description:
      'You and your staff need focused tools that make way for discipleship and spiritual growth. That’s why our real-time data and engagement tools are designed to seamlessly equip, and not create headaches.',
    feats: [
      { icon: <HomefeedIcon />, name: 'Smart Notifications' },
      { icon: <UserIcon />, name: 'Member Notes' },
      { icon: <StatsIcon />, name: 'Group Activity & Stats' },
      { icon: <UserIcon />, name: 'Member Activity Notifications' },
      { icon: <StatsIcon />, name: 'Reports Library' },
    ],
  },
  {
    key: tabKeys.STAFF,
    title: 'Staff',
    description:
      'The last thing your staff needs is more ways to manage the tasks and members it takes to run the church. Your church tech should enable more intentional discipleship and <span>authentic</span> connections.',
    feats: [
      { icon: <StatsIcon />, name: 'Real-time Data' },
      { icon: <HomefeedIcon />, name: 'Homefeed Curation & Analytics' },
      { icon: <GroupIcon />, name: 'Group Activity & Stats' },
      { icon: <EventIcon />, name: 'Event Check-ins' },
      { icon: <UserIcon />, name: 'Member Management' },
    ],
  },
  {
    key: tabKeys.LEADER,
    title: 'Leader',
    description:
      'Volunteer Leaders take time out of their busy lives to help grow your church. So equip them with tools that make their roles easier and more focused on the people in their groups. <span>It’s not about homework.</span>',
    feats: [
      { icon: <StatsIcon />, name: 'Submit Attendance' },
      { icon: <EventIcon />, name: 'Create Events & View RSVPs' },
      { icon: <UserIcon />, name: 'Member Management' },
      { icon: <WelcomeVideoIcon />, name: 'Custom Welcome Video' },
      { icon: <InboxIcon />, name: 'Group Chat' },
    ],
  },
  {
    key: tabKeys.MEMBER,
    title: 'Member',
    description:
      'Whether they’re all-in or occassional attendees, Members of your church are looking for an easy way to connect, stay informed, and engage with the friends they meet along the way. So make it simple.',
    feats: [
      { icon: <HomefeedIcon />, name: 'Custom App and Homefeed' },
      { icon: <GroupIcon />, name: 'Group Search' },
      { icon: <InboxIcon />, name: 'Group Chat and Messaging' },
      { icon: <EventIcon />, name: 'Events' },
      { icon: <StreamingIcon />, name: 'Streaming and WatchTogether' },
    ],
  },
];

const Roles = ({ width }) => {
  const [activeTab, setActiveTab] = useState(tabKeys.PASTOR);
  const [activeCard, setActiveCard] = useState(
    RoleFeatures.find((item) => item.key === tabKeys.PASTOR)
  );

  return (
    <>
      <GradientSection id='quote-section'>
        <QuoteSection>
          <Frame>
            <p>
              “Togather was built to help retain current members and grow church
              attendance using proven Biblical principals. If growth is
              important to your church, Togather is a must for your community.”
            </p>
            <span>Josh Kelsey</span>
          </Frame>
          <img src='./static/images/homepage/josh-kelsey.png' />
        </QuoteSection>
      </GradientSection>
      <RolesLayout>
        <HeadingOne>Empower every member of your church</HeadingOne>
        <Paragraph>
          Togather is built for everyone in your church. Our ChurchOS provides a
          seamless solution for churches who utilize small groups to engage with
          and manage its members, volunteer leaders, and staff.
        </Paragraph>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <TabsContainer>
            {width <= 680 && (
              <TabArrow
                onClick={() => {
                  if (activeTab >= 2) {
                    setActiveTab(activeTab - 1);
                    setActiveCard(
                      RoleFeatures.find((item) => item.key === activeTab - 1)
                    );
                  }
                }}
              >
                <LeftArrowIcon />
              </TabArrow>
            )}
            {Object.entries(tabKeys).map(([key, value]) => (
              <Tab
                key={'tab' + key}
                active={activeTab === value}
                onClick={() => {
                  if (width > 680) {
                    setActiveTab(value);
                    setActiveCard(
                      RoleFeatures.find((item) => item.key === value)
                    );
                  }
                }}
              >
                {key.toLowerCase()}
              </Tab>
            ))}
            {width <= 680 && (
              <TabArrow
                onClick={() => {
                  if (activeTab <= 3) {
                    setActiveTab(activeTab + 1);
                    setActiveCard(
                      RoleFeatures.find((item) => item.key === activeTab + 1)
                    );
                  }
                }}
              >
                <RightArrowIcon />
              </TabArrow>
            )}
          </TabsContainer>
        </div>
        <Card>
          <CardBody>
            <CardTitle>{activeCard.title}</CardTitle>
            <CardDescription
              dangerouslySetInnerHTML={{
                __html: activeCard.description,
              }}
            />
            <RoleFeatsLayout>
              {activeCard.feats.map((rFeat, i) => (
                <RoleFeat key={'rFeat' + i}>
                  {rFeat.icon}
                  <p>{rFeat.name}</p>
                </RoleFeat>
              ))}
            </RoleFeatsLayout>
          </CardBody>
          <CardImageContianers>
            {Object.entries(roleImages).map(([key, img]) => (
              <CardImage
                key={'role-img' + key}
                show={activeCard.key === +key}
                bg={img}
              />
            ))}
          </CardImageContianers>
        </Card>
      </RolesLayout>
    </>
  );
};

export default Roles;
