import {
  Col,
  ContentCont,
  Description,
  GradContainer,
  GradientSection,
  GradText,
  Title,
} from './index.styles';
import TimeIcon from '../../../assets/images/homepage/time.svg';
import FocusIcon from '../../../assets/images/homepage/focus.svg';
import FrustrationIcon from '../../../assets/images/homepage/frustration.svg';
import MoneyIcon from '../../../assets/images/homepage/money.svg';

const Cuttings = [
  {
    title: 'Time',
    icon: <TimeIcon/>
  },
  {
    title: 'Focus',
    icon: <FocusIcon/>
  },
  {
    title: 'Frustration',
    icon: <FrustrationIcon/>
  },
  {
    title: 'Money',
    icon: <MoneyIcon/>
  },
];

const Costing = () => {
  return (
    <>
      <GradientSection id="costing">
        <GradContainer>
          <GradText>How much is your current church tech costing you?</GradText>
        </GradContainer>
      </GradientSection>
      <ContentCont>
        {Cuttings.map((cItem) => (
          <Col key={cItem.title}>
            {cItem.icon}
            <Title>{cItem.title}</Title>
          </Col>
        ))}
      </ContentCont>
    </>
  );
};

export default Costing;
