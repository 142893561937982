import styled from "styled-components";

export const RolesLayout = styled.div`
  width: 1120px;
  margin: 200px auto 0;

  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    width: 100%;
    margin: 120px 0 0;
    padding: 0 32px;
  }

  @media (max-width: 680px) {
    margin-top: 80px;
    padding: 0 16px;
  }
`;

export const HeadingOne = styled.h2`
  font-family: "ivypresto-display",serif;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 110%;

  text-align: center;

  color: #252527;

  span {
    color: #FEB240;
  }

  @media (max-width: 1024px) {
    font-size: 48px;
    width: 524px;
    margin: 0 auto;
  }

  @media (max-width: 680px) {
    font-size: 26px;
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  width: 830px;
  margin: 24px auto 0;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;

  text-align: center;

  color: #2C2C3A;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 680px) {
    margin-top: 16px;

    font-size: 15px;
    line-height: 120%;
  }
`;

export const TabsContainer = styled.div`
  min-width: 780px;
  margin-top: 48px;

  display: flex;
  flex-direction: row;
  gap: 8px;

  @media (max-width: 800px) {
    margin-top: 32px;
    min-width: 100%;
  }

  @media (max-width: 680px) {
    align-items: center;
    justify-content: space-around;
  }

`;

export const Tab = styled.div`
  flex: 1;
  background: ${({active}) => active ? '#F8F8FA' : 'transparent'};
  border: 1px solid #F8F8FA;
  border-radius: 16px;
  padding: 11px 0;
  cursor: pointer;

  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;

  text-align: center;
  text-transform: capitalize;

  :hover {
    background: #F8F8FA;
  }

  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 120%;
  }

  @media (max-width: 680px) {
    font-size: 18px;
    display: ${({active}) => active ? 'block' : 'none'};

    width: 170px;
    flex: 0 1 auto;
  }
`;

export const TabArrow = styled.div`
  width: 32px;
  height: 32px;

  background: rgba(167, 167, 191, 0.1);
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  height: 505px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  border-radius: 20px;

  box-shadow: 0px 134.283px 53.7132px rgba(0, 0, 0, 0.01), 0px 75.1985px 45.6563px rgba(0, 0, 0, 0.05), 0px 33.5708px 33.5708px rgba(0, 0, 0, 0.09), 0px 8.05699px 18.7996px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 5.03757px 15.1127px rgba(0, 0, 0, 0.06);

  @media (max-width: 1024px) {
    height: 553px;
  }

  @media (max-width: 680px) {
    height: 562px;
    flex-direction: column-reverse;
  }
`;


export const CardImageContianers = styled.div`
  width: 50%;
  position: relative;

  @media (max-width: 680px) {
    width: 100%;
    height: 200px;
  }
`;

export const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({bg}) => bg});
  background-size: cover;
  background-position: center;

  visibility: ${({show}) => show ? 'visible' : 'hidden'};
  position: absolute;
  top: 0;

  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  @media (max-width: 680px) {
    border-bottom-right-radius: 0px;
    border-top-left-radius: 20px;
    width: 100%;
    height: 200px;
  }
`;

export const CardBody = styled.div`
  width: 50%;
  padding: 48px 64px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 40px 48px;
  }

  @media (max-width: 680px) {
    padding: 24px;
    width: 100%;
  }
`;

export const CardTitle = styled.p`
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;

  color: #252527;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 140%;
  }

  @media (max-width: 680px) {
    font-size: 16px;
  }
`;

export const CardDescription = styled.p`
  margin-top: 16px;
  font-family: 'Satoshi', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;

  color: #2C2C3A;

  span {
    font-family: 'Satoshi', sans-serif;
  }

  @media (max-width: 1024px) {
    margin-top: 8px;
  }

  @media (max-width: 680px) {
    font-size: 15px;
    line-height: 120%;
    height: 90px;

    span {
      display: none;
    }
  }
`;

export const RoleFeatsLayout = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 1024px) {
    gap: 16px;
  }

  @media (max-width: 680px) {
    margin-top: 17px;
    gap: 13px;
  }
`;

export const RoleFeat = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-left: 15px;
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;

    color: #252527;
  }

  @media (max-width: 1024px) {
    p {
      font-size: 15px;
      line-height: 120%;
    }
  }
`;

export const QuoteSection = styled.div`
  width: 840px;
  margin: 0px auto;
  padding: 104px 0px;
  gap: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 120px 32px;

    img {
      width: 220px;
      height: 220px;
    }
  }

  @media (max-width: 680px) {
    flex-direction: column-reverse;
    margin-top: 80px;
    padding: 32px 16px;
  }
`;

export const Frame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 500px;

  p {
    font-family: "ivypresto-display",serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;

    color: #FFFFFF;
  }

  span {
    font-family: 'Satoshi', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    color: #FFFFFF;
  }

  @media (max-width: 680px) {
    width: 100%;
    align-items: center;

    p {
      font-size: 20px;
      text-align: center;
      width: 288px;
    }

    span {
      font-size: 15px;
    }
  }
`;